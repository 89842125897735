import React, { useEffect } from 'react';
import { FormGroup, Row, Col, Button } from 'reactstrap';
import { fetchCustomerCode } from 'services/addressBook';
import EntryGrid from 'components/Inputs/EntryGrid';
import TextBox from 'components/Inputs/TextBox';
import SelectBox from 'components/SelectBox';
import { useSelector, useDispatch } from 'react-redux';
import CustomerInfoForm from './customerInfoForm';
import SelectModal from './selectModal';
import { t } from 'i18next';
import * as InformationAction from 'store/actions/information';

function InputForm({
  register,
  errors,
  control,
  options,
  editData,
  addNew = true,
  types = ['sender', 'receiver'],
  setValue,
  getValues,
  // noCard = false,
  selectedOrder = null,
  onSelectAddress,
}) {
  // const user = useSelector((state) => state.user.userInfo);

  const marketing_persons = useSelector((state) => state.information.marketing_persons);
  const marketingPersonOptions = marketing_persons?.map((e) => {
    return {
      label: e.label,
      value: e._id,
    };
  });

  const customerTypeOptions = [
    {
      label: 'Normal Customer',
      value: 'Normal',
    },
    {
      label: 'VIP Customer',
      value: 'VIP',
    },
  ];

  const initValues = {
    code: null,
    marketing_person: '',
    name: '',
    company: '',
    phone: '',
    country: null,
    region: null,
    district: null,
    township: null,
    address: '',
  };

  const [openSelectModal, setSelectModal] = React.useState(false);
  const [selectedAddress, setSelectedAddress] = React.useState(false);
  const [selectedCode, setSelectedCode] = React.useState(false);
  const dispatch = useDispatch();

  const role = useSelector((state) => state.user.role);

  const isSuperAdmin = role.role.rolename === 'Super Administrator';

  const branches = useSelector((state) => state.information.branches);
  const branchOptions = branches?.map((e) => {
    return {
      label: e.label,
      value: e._id,
    };
  });

  React.useEffect(() => {
    setSelectedAddress(selectedOrder ? true : false);
  }, [selectedOrder]);

  // useEffect(() => {
  //   if (user) {
  //     setValue('marketing_person', user?.employee?._id);
  //   }
  // }, [setValue, user]);

  useEffect(() => {
    dispatch(InformationAction.getBranch());
  }, [dispatch, InformationAction]);

  const onFetchCode = async () => {
    let response = await fetchCustomerCode();
    if (response) {
      setSelectedCode(true);
      setValue('code', response.code);
    }
  };

  return (
    <>
      {addNew && (
        <EntryGrid title={`${t('basic')} ${t('information')}`}>
          <Row>
            <Col xs={4}>
              <FormGroup>
                <label>{t('code')}</label>
                <div className='d-flex'>
                  <div style={{ flex: 1 }}>
                    <TextBox
                      className='form-control'
                      placeholder={t('code')}
                      type='text'
                      registerProps={register('code', {
                        required: `${t('code')} ${t('is_required')}`,
                      })}
                      errors={errors.code}
                      disabled
                    />
                  </div>
                  <Button
                    color='success'
                    type='button'
                    size='sm'
                    style={{ maxHeight: 35 }}
                    onClick={() => onFetchCode()}
                    disabled={selectedCode || editData}
                  >
                    <i className='fa fa-plus text-white'></i>
                  </Button>
                </div>
              </FormGroup>
            </Col>
            <Col xs={4}>
              <FormGroup>
                <label>{t('address_book.customer_type')}</label>
                <SelectBox
                  control={control}
                  name='customer_type'
                  label={t('address_book.customer_type')}
                  options={customerTypeOptions}
                  value={editData?.customer_type}
                  rules={{ required: true }}
                />
              </FormGroup>
            </Col>
            <Col xs={4}>
              <FormGroup>
                <label>{t('address_book.sale_person')}</label>
                <SelectBox
                  control={control}
                  name='marketing_person'
                  label={t('address_book.sale_person')}
                  options={marketingPersonOptions}
                  value={editData?.marketing_person}
                  rules={{ required: true }}
                />
              </FormGroup>
            </Col>
            <Col xs={4}>
              <FormGroup>
                <label>{t('waybill_entry.destination')}</label>
                <SelectBox
                  label={t('waybill_entry.destination')}
                  control={control}
                  name='branch'
                  options={branchOptions}
                  rules={{ required: true }}
                />
              </FormGroup>
            </Col>
          </Row>
        </EntryGrid>
      )}
      <Row>
        <Col md={6} xs={12}>
          <EntryGrid title={`${t('address_book.sender')} ${t('information')}`}>
            {!addNew && (
              <Row>
                <Col md={6} xs={12}>
                  <FormGroup>
                    <label>{t('code')}</label>
                    <div className='d-flex'>
                      <div style={{ flex: 1 }}>
                        <TextBox
                          className='form-control'
                          placeholder={t('code')}
                          type='text'
                          registerProps={register('senderinfo.code')}
                          errors={errors.code}
                          disabled={!isSuperAdmin}
                        />
                      </div>
                      <Button
                        color='success'
                        type='button'
                        size='sm'
                        disabled={editData || selectedAddress}
                        style={{ maxHeight: 35 }}
                        onClick={() => setSelectModal(true)}
                      >
                        <i className='fa fa-plus text-white'></i>
                      </Button>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
            )}
            <CustomerInfoForm
              errors={errors}
              type={types[0] ? types[0] : 'sender'}
              register={register}
              control={control}
              options={options}
              editData={editData}
              getValues={getValues}
              addNew={addNew}
              selectedAddress={selectedAddress}
              resetButton={
                <Button
                  color='info'
                  size='sm'
                  onClick={() => {
                    setSelectedAddress(false);
                    setValue('addressId', null);
                    setValue('senderinfo', initValues);
                    setValue('receiverinfo', initValues);
                  }}
                >
                  {t('reset')}
                </Button>
              }
            />
          </EntryGrid>
        </Col>
        <Col md={6} xs={12}>
          <EntryGrid title={`${t('address_book.receiver')} ${t('information')}`}>
            <CustomerInfoForm
              errors={errors}
              type={types[1] ? types[1] : 'receiver'}
              register={register}
              control={control}
              options={options}
              editData={editData}
              getValues={getValues}
              addNew={addNew}
              selectedAddress={selectedAddress}
            />
            {!addNew && <div style={{ height: 80 }}></div>}
          </EntryGrid>
        </Col>
      </Row>
      <SelectModal
        modalOpen={openSelectModal}
        onToggle={() => {
          setSelectModal(!openSelectModal);
        }}
        onSelect={(e) => {
          setSelectedAddress(true);
          setValue('senderinfo', e.sender);
          setValue('senderinfo.marketing_person', e.marketing_person);

          setValue('senderinfo.code', e.customer_type === 'VIP' ? 'VIP-' + e.code : e.code);
          setValue('receiverinfo', e.receiver);

          setValue('addressId', e._id);
          setValue('basicinfo.destination', e.branch?._id);
          setSelectModal(!openSelectModal);
          onSelectAddress(e);
        }}
      />
    </>
  );
}
export default InputForm;
